import React from 'react';
import { bool, func, node, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';

import css from './MainPanelHeader.module.css';

const MainPanelHeader = props => {
  const {
    rootClassName,
    className,
    children,
    sortByComponent,
    listingsAreLoaded,
    resultsCount,
    searchInProgress,
    noResultsInfo,
    searchParams,
    urlQueryParams,
  } = props;
const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.searchOptions}>
        <div className={css.searchResultSummary}>
          <span className={css.resultsFound}>
            <span className={css.filterBy}>Filter by :</span>
            {searchInProgress ? (
              <FormattedMessage id="MainPanelHeader.loadingResults" />
            ) : Object.entries(urlQueryParams).filter((e) => e[0] != "sort").map(elem =>
              <span className={css.filterSelect}>
                <b>{elem[0].split('pub_')[1] || elem[0]}</b>: {elem[1]}
              </span>
            )}
          </span>
        </div>
        <div className={css.sortyByWrapper}>
          <span className={css.sortyBy}>
            <FormattedMessage id="MainPanelHeader.sortBy" />
          </span>
          {sortByComponent}
        </div>
      </div>

      {children}

      {noResultsInfo ? noResultsInfo : null}
    </div>
  );
};

MainPanelHeader.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchInProgress: false,
  sortByComponent: null,
};

MainPanelHeader.propTypes = {
  rootClassName: string,
  className: string,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchInProgress: bool,
  sortByComponent: node,
};

export default MainPanelHeader;
